import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { HelmetProvider } from 'react-helmet-async';
import { Route, Switch, useHistory } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import About from './pages/About';
import Contact from './pages/Contact';
import Home from './pages/Home';
import Work from './pages/work';

function App() {
  const [menuOpen, setMenuOpen] = useState(true)

  return (
    <HelmetProvider>
      <Helmet defer={false}>‍
        <title>Studio817</title>
        {/* <meta property="og:image" content={ogImage} /> */}
      </Helmet>
      <div className="App">
        <Header setMenuOpen={setMenuOpen} menuOpen={menuOpen} />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/about">
            <About />
          </Route>
          <Route exact path="/contact">
            <Contact />
          </Route>
          <Route path="/work/:name">
            <Work />
          </Route>
        </Switch>
      </div>
    </HelmetProvider>
  );
}

export default App;
