import React, { useState } from "react";

const Contact = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    return (
        <>
            <form className="contact-form">
                <label for="name">Name</label>
                <input id="name" type="text" onChange={(e) => setName(e.target.value)} />
                <label for="email">Email</label>
                <input id="email" type="email" onChange={(e) => setEmail(e.target.value)} />
                <label for="message">Message</label>
                <textarea id="message" onChange={(e) => setMessage(e.target.value)} />
                <input type="submit" />
            </form>
        </>
    )
}

export default Contact;