import React from "react";

const About = () => {
    const image = require('../assets/altLogo.jpeg')
    return (
        <div className="aboutContainer">
            <img src={image} width="265px" height="265px" />
            <h3>Media, marketing and brand strategy.</h3>
        </div>
    )
}

export default About