import React, { useRef, useState, useEffect } from "react";
import { VolumeHighOutline, VolumeMuteOutline } from "react-ionicons";
import useElementsOnScreen from "../hooks/useElementsOnScreen";

const isSafari = () => {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
};


export default function VideoA(props) {
    console.log(props)
    // const videoParentRef = useRef();
    const [shouldUseImage, setShouldUseImage] = useState(false);
    const [vidVolOn, setVolOn] = useState(false);
    const [videoParentRef, isVisible] = useElementsOnScreen({
        // threshold: 0.85
    })



    useEffect(() => {
        // check if user agent is safari and we have the ref to the container <div />
        if (isSafari() && videoParentRef.current) {
            // obtain reference to the video element
            const player = videoParentRef.current.children[0];

            // if the reference to video player has been obtained
            if (player) {
                // set the video attributes using javascript as per the
                // webkit Policy
                player.controls = true;
                player.playsinline = true;
                player.muted = true;
                player.setAttribute("muted", "controls", ""); // leave no stones unturned :)
                player.autoplay = true;

                // Let's wait for an event loop tick and be async.
                setTimeout(() => {
                    // player.play() might return a promise but it's not guaranteed crossbrowser.
                    const promise = player.play();
                    // let's play safe to ensure that if we do have a promise
                    if (promise.then) {
                        promise
                            .then(() => { })
                            .catch(() => {
                                // if promise fails, hide the video and fallback to <img> tag
                                videoParentRef.current.style.display = "none";
                                setShouldUseImage(true);
                            });
                    }
                }, 0);
            }
        }
        if (isVisible !== false) {

            if (vidVolOn === true) {
                const vid = document.querySelector("video")
                console.log(vid)
                vid.volume = 0.9;
                vid.muted = false;
            }
            if (vidVolOn === false) {
                const vid = document.querySelector("video")
                console.log(vid)
                vid.volume = 0
            }
        }
    }, [vidVolOn, isVisible]);

    return shouldUseImage ? (
        <img src={props.src} alt="Muted Video" />
    ) : (
        <>
            <div
                onClick={() => props.setItemOpen(props.props)}
                style={{ position: "relative" }}
                ref={videoParentRef}
                dangerouslySetInnerHTML={{
                    __html: `
                <video
                loop
                muted
                controls
                autoplay
                playsinline
                preload="metadata"
                >
                <source src="${(props.props.vidsrc)}" type="video/mp4" />
                </video>`
                }}
            />
            {/* <div className="volBtn" onClick={() => setVolOn(!vidVolOn)}>
                {vidVolOn === true ? (
                    <VolumeHighOutline
                        color={'#000'}
                        height="25px"
                        width="25px"
                    />
                ) : (
                    <VolumeMuteOutline
                        color={'#000'}
                        height="25px"
                        width="25px"
                    />
                )}
            </div> */}
        </>
    );
}
