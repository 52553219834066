import React, { useEffect, useState } from "react"
import Video from "../components/Video";
import "../App.css"
import { Link, useHistory } from "react-router-dom";

const Home = () => {
    const [itemOpen, setItemOpen] = useState(null);

    const urls = [
        {
            type: "img",
            name: "hydroFlask",
            copyTitle: "Kala + Hydro Flask",
            altMediaMobile: [
                { src: 'https://studioeightoneseven.com/assets/hydroflask2.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/hydroflask3.jpeg' }
            ],
            altMediaMobile1: 'https://studioeightoneseven.com/assets/hydroflask2.jpeg',
            altMediaMobile2: 'https://studioeightoneseven.com/assets/hydroflask3.jpeg',
            copyBody: "Marketing content for Kala Brand Music Co.",
            altMedia: [
                { src: 'https://studioeightoneseven.com/assets/hydroflask2.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/hydroflask3.jpeg' },
            ],
            src: 'https://studioeightoneseven.com/assets/hydroFlask.jpeg',
            thumbnail: 'https://studioeightoneseven.com/assets/thumbnails/thumbnails/hydroflask.jpg'
        },
        {
            type: "video",
            name: "yoga",
            copyTitle: "Jacob Manning Yoga",
            copyBody: "Videography, photography & marketing content for Jacob Manning Yoga. 300k+ instagram followers.",
            altsrc: 'https://studioeightoneseven.com/assets/yoga-page-main.png',
            srcAlt: 'https://studioeightoneseven.com/assets/Jacob-Manning-Popstar-20200919-fr.mp4',
            altMedia: [
                { src: 'https://studioeightoneseven.com/assets/yoga-page2.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/yoga-page3.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/yoga-page4.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/yoga-page5.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/yoga-page6.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/yoga-page7.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/yoga-page8.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/yoga-page9.jpeg' },
            ],
            lastPic: 'https://studioeightoneseven.com/assets/yoag-page10.jpeg',
            thumbnail: 'https://studioeightoneseven.com/assets/jakethumb.mp4'
        },
        {
            type: "img",
            name: "elvis",
            thumbnail: 'https://studioeightoneseven.com/assets/thumbnails/thumbnails/elvis.jpg',
            altsrc: 'https://studioeightoneseven.com/assets/elvis211.jpeg',
            threeSquares: [
                { src: 'https://studioeightoneseven.com/assets/elvissq11.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/elvissq21.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/elvissq31.jpeg' }
            ],
            altSrcTwo: 'https://studioeightoneseven.com/assets/elvis51.jpeg',
            rowTwo: [
                { src: 'https://studioeightoneseven.com/assets/elvis61.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/elvis71.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/elvis81.jpeg' }
            ],
            screenShot: 'https://studioeightoneseven.com/assets/elvisScreenShot1.jpg',
            walmart: 'https://studioeightoneseven.com/assets/elvisWalmart1.png',
            catalog1: 'https://studioeightoneseven.com/assets/elvis111.jpeg',
            catalog2: 'https://studioeightoneseven.com/assets/elvis121.jpeg',
            catalog3: 'https://studioeightoneseven.com/assets/elvis131.jpeg',
            copyTitle: "Elvis™ Signature Series",
            copyBody: "Graphic top artwork & Marketing content for Kala Brand Music Co.",
            copyBody2: "The Elvis™ Learn To Play Series was a collaboration with the King of Rock n Roll and Kala Brand Ukuleles. Tutorial videos were made to learn how to play some of Elvis biggest hits throughout his career with these three collectable ukuleles from his most recognizable eras.",
            copyTitle2: "Top photo - Left to Right",
            copyBody3: "Viva Las Vegas",
            copyBody4: "Rockabilly",
            copyBody5: "Blue Hawaii"
        },
        {
            type: "video",
            name: "polite",
            thumbnail: 'https://studioeightoneseven.com/assets/Polite-Ree-1x1l.mp4',
            vidsrc: 'https://studioeightoneseven.com/assets/polite-main.mp4',
            copyTitle: "Polite Hemp",
            copyBody: "Videography & marketing content for Polite™ Hemp."
        },
        {
            type: "img",
            name: "bakithi",
            thumbnail: 'https://studioeightoneseven.com/assets/thumbnails/thumbnails/bakithi.jpg',
            src: 'https://studioeightoneseven.com/assets/bakithiMain.jpg',
            copyTitle: "Kala + Bakithi Kumalo",
            copyBody: "Marketing content for Kala Brand Music Co.",
            altMedia: [
                { src: "https://studioeightoneseven.com/assets/bakithi1.jpeg" },
                { src: "https://studioeightoneseven.com/assets/bakithi2.jpeg" }
            ],
            kumaloBanner: "https://studioeightoneseven.com/assets/kumalo.jpeg",
            grid: [
                { src: 'https://studioeightoneseven.com/assets/bakithi1G.jpg' },
                { src: 'https://studioeightoneseven.com/assets/bakithi2G.jpg' },
                { src: 'https://studioeightoneseven.com/assets/bakithi3G.jpg' },
                { src: 'https://studioeightoneseven.com/assets/bakithi4G.jpg' },
                { src: 'https://studioeightoneseven.com/assets/bakithi5G.jpg' },
            ],
            kumalo2: 'https://studioeightoneseven.com/assets/kumalo2.jpeg'
        },
        {
            type: "video",
            name: "space-babes",
            thumbnail: 'https://studioeightoneseven.com/assets/garythumb.mp4',
            srcAlt: 'https://studioeightoneseven.com/assets/Gary-Final-HQ-202002-fr.mp4',
            copyTitle: "Gary Paintin",
            copyBody: "Videography, photography & marketing content for Gary Paintin.",
            altMedia: [
                { src: 'https://studioeightoneseven.com/assets/gary2.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/gary3.jpeg' },
            ],
            altsrc: 'https://studioeightoneseven.com/assets/gary4.jpeg',
            secondMedia: [
                { src: 'https://studioeightoneseven.com/assets/gary5.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/gary6.jpeg' },
            ]
        },
        {
            type: "img",
            name: "winehop",
            copyBody: "App Design/Development, Branding, photography, videography, marketing content, UI/UX design for WineHop Inc.",
            copyTitle: "WineHop Inc.",
            thumbnail: 'https://studioeightoneseven.com/assets/thumbnails/thumbnails/winehop-web.jpg',
            src: 'https://studioeightoneseven.com/assets//wh-webpage.jpeg'
        },
        {
            type: "img",
            name: "kala-web",
            copyTitle: "Kala Brand Music Co. Website",
            copyBody: "UI/UX design & stock photography for Kala Brand Music Co.",
            thumbnail: 'https://studioeightoneseven.com/assets/thumbnails/thumbnails/kala-web.jpg',
            src: 'https://studioeightoneseven.com/assets/kala-meranti-web.jpg'
        },
        {
            type: "img",
            name: "monday",
            thumbnail: 'https://studioeightoneseven.com/assets/thumbnails/thumbnails/monday.jpg',
            src: 'https://studioeightoneseven.com/assets/monday-bakery.jpeg',
            altMedia: [
                { src: 'https://studioeightoneseven.com/assets/monday2.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/monday3.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/monday4.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/monday5.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/monday6.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/monday7.jpeg' },
            ],
            copyTitle: "Monday Bakery",
            copyBody: "Branding and merchandise/apparel design content for Monday Bakery."
        },
        {
            type: "img",
            thumbnail: 'https://studioeightoneseven.com/assets/thumbnails/thumbnails/spalted-mango.jpg',
            name: "spalted-mango",
            copyTitle: "Spalted Mango Ukulele",
            copyBody: "Marketing content for Kala Brand Music Co.",
            copyBody2: "These photos were created to promote The Spalted Mango series. The goal was to showcase the remarkable figuring of shapes and patterns that appear on these instruments.",
            copyBody3: "Photo was used in Kala Brand Music Co. 2021 catalog.",
            grid: [
                { src: 'https://studioeightoneseven.com/assets/mango1G.jpg' },
                { src: 'https://studioeightoneseven.com/assets/mango2G.jpg' },
                { src: 'https://studioeightoneseven.com/assets/mango3G.jpg' },
                { src: 'https://studioeightoneseven.com/assets/mango4G.jpg' },
            ],
            altMedia: [
                { src: 'https://studioeightoneseven.com/assets/mango2.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/mango3.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/mango4.jpeg' },

            ],
            altBig: 'https://studioeightoneseven.com/assets/mango5.jpeg',
            altMedia2: [
                { src: 'https://studioeightoneseven.com/assets/mango6.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/mango7.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/mango8.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/mango9.jpeg' }
            ]
        },
        {
            type: "img",
            thumbnail: 'https://studioeightoneseven.com/assets/thumbnails/thumbnails/teak.jpg',
            src: 'https://studioeightoneseven.com/assets/desk4.jpeg',
            name: "teak",
            copyTitle: "Teak",
            copyTitle2: "Tri-Top Series",
            copyBody: "Marketing content for Kala Brand Music Co.",
            altMedia: [
                { src: 'https://studioeightoneseven.com/assets/teak2.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/teak3.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/teak4.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/teak5.jpeg' },
            ],
            grid: [
                { src: 'https://studioeightoneseven.com/assets/teak11G.jpg' },
                { src: 'https://studioeightoneseven.com/assets/teak21G.jpg' },
                { src: 'https://studioeightoneseven.com/assets/teak31G.jpg' },
                { src: 'https://studioeightoneseven.com/assets/teak41G.jpg' },
            ],
            altBig: 'https://studioeightoneseven.com/assets/teak6.jpeg',
            altBig2: 'https://studioeightoneseven.com/assets/teak7.jpeg'

        },
        {
            type: "img",
            name: "yellow",
            thumbnail: 'https://studioeightoneseven.com/assets/thumbnails/thumbnails/yellow.jpg',
            copyTitle: "Surf Series",
            copyBody: "Marketing content for Kala Brand Music Co. Surf Series ukulele.",
            altBig: 'https://studioeightoneseven.com/assets/yellow2.jpeg',
            grid1: [
                { src: 'https://studioeightoneseven.com/assets/yellow3.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/yellow4.jpeg' },
            ],
            altBig2: 'https://studioeightoneseven.com/assets/yellow51.jpg',
            grid2: [
                { src: 'https://studioeightoneseven.com/assets/yellow61.jpg' },
                { src: 'https://studioeightoneseven.com/assets/yellow71.jpg' },
            ],
            grid3: [
                { src: 'https://studioeightoneseven.com/assets/yellow81.jpg' },
                { src: 'https://studioeightoneseven.com/assets/yellow91.jpg' },
            ],
            altBig3: 'https://studioeightoneseven.com/assets/yellow17.jpeg',
            grid4: [
                { src: 'https://studioeightoneseven.com/assets/surf1G.jpg' },
                { src: 'https://studioeightoneseven.com/assets/surf2G.jpg' },
                { src: 'https://studioeightoneseven.com/assets/surf3G.jpg' },
                { src: 'https://studioeightoneseven.com/assets/surf4G.jpg' },
                { src: 'https://studioeightoneseven.com/assets/surf5G.jpg' },
                { src: 'https://studioeightoneseven.com/assets/surf61G.jpg' },
                { src: 'https://studioeightoneseven.com/assets/surf7G.jpg' },

            ],
            altMedia: [
                { src: 'https://studioeightoneseven.com/assets/yellow3.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/yellow4.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/yellow5.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/yellow6.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/yellow7.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/yellow8.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/yellow9.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/yellow10.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/yellow11.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/yellow12.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/yellow13.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/yellow14.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/yellow15.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/yellow16.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/yellow17.jpeg' }
            ]
        },
        {
            type: "video",
            name: "pacific-walnut",
            thumbnail: 'https://studioeightoneseven.com/assets/video2.mp4',
            srcAlt: 'https://studioeightoneseven.com/assets/PWalnut-Video-HQ-Full-222204.mp4',
            altMedia: [
                { src: 'https://studioeightoneseven.com/assets/pacificW2.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/pacificW3.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/pacificW4.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/pacificW5.jpeg' },
            ],
            grid: [
                { src: 'https://studioeightoneseven.com/assets/pacific1G.jpg' },
                { src: 'https://studioeightoneseven.com/assets/pacific2G.jpg' },
                { src: 'https://studioeightoneseven.com/assets/pacific3G.jpg' },
                { src: 'https://studioeightoneseven.com/assets/pacific4G.jpg' },
                { src: 'https://studioeightoneseven.com/assets/pacific5G.jpg' },
            ],
            copyTitle: "Pacific Walnut Line",
            copyBody: "Vidography, Photography & Art Direction for Kala Brand Music Co.",
            copyBody2: "The Pacific Walnut line is contrasted by the beautiful, dark brown patterns in the grain. This look created by the contrast gives these ukuleles a bold look.",
            copyBody3: "Location was scouted and model was styled to have a casual look in nature.",
            copyBody4: "A one take slow motion video for pleasing visual aesthetic walking through the woods, along with multiple photographs.",
            copyBody5: "Photo with red jacket was used for the cover of the Kala Brand Music Co. 2019 catalog.",
            copyBody6: "Stock photography was also taken of the instrument for ecommerce, marketing materials & worldwide dealers."
        },
        {
            type: "img",
            thumbnail: 'https://studioeightoneseven.com/assets/thumbnails/thumbnails/ubassflat.jpg',
            name: 'ubass-flat',
            copyTitle: "Kala U•BASS Flatwound Strings",
            copyBody: "Marketing content and packaging design for Kala Brand Music Co.",
            altBig: 'https://studioeightoneseven.com/assets/ubassflat1.jpeg',
            altMedia: [
                { src: 'https://studioeightoneseven.com/assets/ubassflat2.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/ubassflat3.jpeg' }
            ],
            altMedia2: [
                { src: 'https://studioeightoneseven.com/assets/ubassflat4.jpeg' },
                { src: 'https://studioeightoneseven.com/assets/ubassflat5.jpeg' }
            ]
        },
        {
            type: "video",
            name: "aja-yoga",
            thumbnail: 'https://studioeightoneseven.com/assets/ajathumb.mp4',
            srcAlt: 'https://studioeightoneseven.com/assets/Aja-Woods-Yoga-HQ_201909.mp4',
            copyTitle: "Flow with Aja",
            copyBody: "Marketing for Flow with Aja yoga."
        }
    ];


    return (
        <>

            <div className="home-grid">
                {urls.map((item) => {
                    return (
                        <Link style={{ display: "contents" }} to={{
                            pathname: "/work/" + item.name,
                            state: {
                                data: item
                            }
                        }}>
                            {item.type === "img" && (
                                <img
                                    onClick={() => (window.scrollTo({ top: 0 }), setItemOpen(item))}
                                    src={item.thumbnail}
                                    // style={{ objectPosition: "top" }}
                                    alt="work-example"
                                />
                            )}
                            {item.type === "video" && (
                                <Video setItemOpen={setItemOpen} props={item} />
                            )}
                        </Link>
                    )
                })}
            </div>

        </>
    )
}
export default Home;