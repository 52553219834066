import { useLocation, useParams } from "react-router-dom";
import VideoA from "../components/VideoA";
import VideoMain from "../components/VideoMain";

const Work = (props) => {
    const params = useParams();
    const location = useLocation();
    const { data } = location.state;


    return (
        <>
            {(params.name === "winehop" || params.name === "kala-web") && (
                <div className="work-open-main">
                    <div className="left-work-grid">
                        <img
                            src={data.src}
                            alt="work-pic-big-long"
                            className="work-pic-big-long"
                        />
                    </div>
                    <div className="copy-main">
                        <span className="copyTitle">
                            {data.copyTitle}
                        </span>
                        <span className="copyBody">
                            {data.copyBody}
                        </span>
                    </div>
                </div>

            )}
            {(params.name === "hydroFlask") && (
                <div className="work-open-main">
                    <div className="left-work-grid">
                        <img
                            src={data.src}
                            alt="work-pic-big-long"
                            className="work-pic-big-long mobile-change"
                        />
                        <img
                            src={data.src}
                            alt="work-pic-big-long"
                            style={{ objectFit: "cover", height: "78vh " }}
                            className="work-pic-big-long mobile-hide"
                        />

                        <div className="copy-main-mobile">
                            <span style={{ marginBottom: "9px" }} className="copyTitle">
                                {data.copyTitle}
                            </span>
                            <span style={{ marginBottom: "9px" }} className="copyBody">
                                {data.copyBody}
                            </span>
                        </div>
                        <img
                            src={data.altMediaMobile1}
                            alt="work-pic-big-long"
                            style={{ objectFit: "cover", height: "78vh ", marginBottom: "0px" }}
                            className="work-pic-big-long mobile-show"
                        />
                        <img
                            src={data.altMediaMobile2}
                            alt="work-pic-big-long"
                            style={{ objectFit: "cover", height: "78vh ", marginBottom: "0px" }}
                            className="work-pic-big-long mobile-show"
                        />

                        {data.altMedia.map((item) => {
                            return (
                                <img
                                    src={item.src}
                                    alt="work-pic-big-long"
                                    className="work-pic-big-long mobile-change"
                                />
                            )
                        })}




                    </div>
                    <div className="copy-main">
                        <span className="copyTitle">
                            {data.copyTitle}
                        </span>
                        <span className="copyBody">
                            {data.copyBody}
                        </span>
                    </div>
                </div>
            )}
            {(params.name === "yoga") && (
                <div className="work-open-main">
                    <div className="left-work-grid">
                        <div className="video-main-alt no-margin">
                            <VideoMain props={data} />
                        </div>
                        <div className="copy-main-mobile">

                            <span className="copyTitle no-margin">
                                {data.copyTitle}
                            </span>
                            <span className="copyBody text-center">
                                {data.copyBody}
                            </span>

                        </div>
                        <div className="grid2x2">
                            {/* <img
                            src={data.src}
                            alt="work-pic-big-long"
                            className="work-pic-grid"
                        /> */}
                            {data.altMedia.map((item) => {
                                return (
                                    <img
                                        src={item.src}
                                        alt="work-pic-big-long"
                                        className="work-pic-grid"
                                    />
                                )
                            })}
                        </div>
                        <img src={data.lastPic}
                            className="work-pic-big-wide last-yoga"
                        />
                    </div>
                    <div className="copy-main">
                        <span className="copyTitle">
                            {data.copyTitle}
                        </span>
                        <span className="copyBody">
                            {data.copyBody}
                        </span>
                    </div>
                </div>
            )}
            {(params.name === "elvis") && (
                <div className="work-open-main">
                    <div className="left-work-grid">
                        <img src={data.altsrc}
                            className="work-pic-big-wide"
                        />
                        <div className="grid3x3">
                            {data.threeSquares.map((item) => {
                                return (
                                    <img src={item.src} />
                                )
                            })}
                        </div>

                        <div className="copy-main-mobile">
                            <span className="copyTitle">
                                {data.copyTitle}
                            </span>
                            <div className="column">
                                <span style={{ marginBottom: "20px" }} className="copyBody">
                                    {data.copyBody}
                                </span>
                                <span style={{ marginBottom: "20px" }} className="copyBody">
                                    {data.copyBody2}
                                </span>
                                {/* <span className="copyBody">
                                <strong>{data.copyTitle2}</strong>
                            </span>
                            <span className="copyBody">
                                {data.copyBody3}
                            </span> */}
                                {/* <span className="copyBody">
                                {data.copyBody4}
                            </span> */}
                                {/* <span className="copyBody">
                                {data.copyBody5}
                            </span> */}
                            </div>
                        </div>

                        <img className="work-pic-big-wide mobile-change" src={data.altSrcTwo} />

                        <div className="grid3x3">
                            {data.rowTwo.map((item) => {
                                return (
                                    <img src={item.src} />
                                )
                            })}
                        </div>

                        <img style={{ marginBottom: "9px" }} className="work-pic-big-wide" src={data.screenShot} />

                        <img style={{ marginBottom: "9px" }} className="work-pic-big-wide" src={data.walmart} />
                        <img style={{ marginBottom: "9px" }} className="work-pic-big-wide" src={data.catalog1} />
                        <img style={{ marginBottom: "9px" }} className="work-pic-big-wide" src={data.catalog2} />
                        <img className="work-pic-big-wide" src={data.catalog3} />
                    </div>
                    <div className="copy-main">
                        <span className="copyTitle">
                            {data.copyTitle}
                        </span>
                        <span style={{ marginBottom: "20px" }} className="copyBody">
                            {data.copyBody}
                        </span>
                        <span style={{ marginBottom: "20px" }} className="copyBody">
                            {data.copyBody2}
                        </span>
                        <span className="copyBody">
                            <strong>{data.copyTitle2}</strong>
                        </span>
                        <span className="copyBody">
                            {data.copyBody3}
                        </span>
                        <span className="copyBody">
                            {data.copyBody4}
                        </span>
                        <span className="copyBody">
                            {data.copyBody5}
                        </span>
                    </div>
                </div>
            )}
            {params.name === "polite" && (
                <div className="work-open-main mobile-col">
                    <div style={{ width: "88%" }} className="copy-main-mobile">
                        <span style={{ marginBottom: "9px" }} className="copyTitle">
                            {data.copyTitle}
                        </span>
                        <span style={{ marginBottom: "9px" }} className="copyBody text-center">
                            {data.copyBody}
                        </span>
                    </div>
                    <div className="video-main polite">
                        <VideoA props={data} />
                    </div>
                    <div className="copy-main" style={{ paddingRight: "3.33rem", width: "33%" }}>
                        <span className="copyTitle">
                            {data.copyTitle}
                        </span>
                        <span className="copyBody">
                            {data.copyBody}
                        </span>
                    </div>
                </div>
            )}
            {params.name === "bakithi" && (
                <div className="work-open-main">
                    <div className="left-work-grid">
                        <img src={data.src}
                            className="work-pic-big-wide"
                        />
                        <div style={{ width: "100%", marginBottom: "9px" }} className="grid2x2">
                            {data.altMedia.map((item) => {
                                return (
                                    <img style={{ height: "100%", objectFit: "cover", width: "100%" }} src={item.src} />
                                )
                            })}
                        </div>
                        <img src={data.kumaloBanner}
                            className="work-pic-big-wide"
                        />
                        <div className="imgGrid5">
                            {data.grid.map((item) => {
                                return (
                                    <img className="imgGrid5__item show" src={item.src} />
                                )
                            })}
                        </div>
                        <img src={data.kumalo2}
                            className="work-pic-big-wide"
                        />
                    </div>
                    <div className="copy-main">
                        <span className="copyTitle">
                            {data.copyTitle}
                        </span>
                        <span style={{ marginBottom: "20px" }} className="copyBody">
                            {data.copyBody}
                        </span>
                    </div>
                </div>
            )}
            {params.name === "monday" && (
                <div className="work-open-main">
                    <div className="left-work-grid">
                        <img src={data.src}
                            className="work-pic-big-wide"
                        />
                        {data.altMedia.map((item) => {
                            return (
                                <img src={item.src} className="work-pic-big-wide" />
                            )
                        })}
                    </div>
                    <div className="copy-main">
                        <span className="copyTitle">
                            {data.copyTitle}
                        </span>
                        <span style={{ marginBottom: "20px" }} className="copyBody">
                            {data.copyBody}
                        </span>
                    </div>
                </div>
            )}
            {params.name === "pacific-walnut" && (
                <div className="work-open-main">
                    <div className="left-work-grid">
                        <div className="video-main-alt">
                            <VideoMain props={data} />
                        </div>
                        {data.altMedia.map((item) => {
                            return (
                                <img src={item.src} className="work-pic-big-wide" />
                            )
                        })}
                        <div className="imgGrid5">
                            {data.grid.map((item) => {
                                return (
                                    <img className="imgGrid5__item show" src={item.src} />
                                )
                            })}
                        </div>
                    </div>

                    <div className="copy-main">
                        <span className="copyTitle">
                            {data.copyTitle}
                        </span>
                        <span style={{ marginBottom: "20px" }} className="copyBody">
                            {data.copyBody}
                        </span>
                        <span style={{ marginBottom: "20px" }} className="copyBody">
                            {data.copyBody2}
                        </span>
                        <span style={{ marginBottom: "20px" }} className="copyBody">
                            {data.copyBody3}
                        </span>
                        <span style={{ marginBottom: "20px" }} className="copyBody">
                            {data.copyBody4}
                        </span>
                        <span style={{ marginBottom: "20px" }} className="copyBody">
                            {data.copyBody5}
                        </span>
                        <span style={{ marginBottom: "20px" }} className="copyBody">
                            {data.copyBody6}
                        </span>
                    </div>
                </div>
            )}
            {params.name === "space-babes" && (
                <div className="work-open-main">
                    <div className="left-work-grid">
                        <div className="video-main-alt">
                            <VideoMain props={data} />
                        </div>
                        <div style={{ width: "100%", marginBottom: "9px" }} className="grid2x2">
                            {data.altMedia.map((item) => {
                                return (
                                    <img style={{ height: "100%", objectFit: "cover", width: "100%" }} src={item.src} />
                                )
                            })}
                        </div>
                        <img src={data.altsrc} className="work-pic-big-wide" />
                        <div style={{ width: "100%", marginBottom: "9px" }} className="grid2x2">
                            {data.secondMedia.map((item) => {
                                return (
                                    <img style={{ height: "100%", objectFit: "cover", width: "100%" }} src={item.src} />
                                )
                            })}
                        </div>
                    </div>
                    <div className="copy-main">
                        <span className="copyTitle">
                            {data.copyTitle}
                        </span>
                        <span className="copyBody">
                            {data.copyBody}
                        </span>
                    </div>
                </div>
            )}
            {params.name === "spalted-mango" && (
                <div className="work-open-main">
                    <div className="left-work-grid">
                        <div className="mango-grid3x3">
                            {data.altMedia.map((item) => {
                                return (
                                    <img src={item.src} />
                                )
                            })}
                        </div>
                        <img className="work-pic-big-wide" src={data.altBig} />
                        <div className="mango-gridAlt">
                            {data.grid.map((item) => {
                                return (
                                    <img src={item.src} />
                                )
                            })}
                        </div>
                    </div>
                    <div className="copy-main">
                        <span className="copyTitle">
                            {data.copyTitle}
                        </span>
                        <span className="copyBody">
                            {data.copyBody}
                        </span>
                    </div>
                </div>
            )}
            {params.name === "teak" && (
                <div className="work-open-main">
                    <div className="left-work-grid">
                        <img className="work-pic-big-wide" src={data.src} />
                        <div className="teak-grid">
                            {data.grid.map((item) => {
                                return (
                                    <img src={item.src} alt="" />
                                )
                            })}
                        </div>
                        <img className="work-pic-big-wide" src={data.altBig} />
                        <img className="work-pic-big-wide" src={data.altBig2} />
                    </div>
                    <div className="copy-main">
                        <span style={{ marginBottom: "0px" }} className="copyTitle">
                            {data.copyTitle}
                        </span>
                        <span className="copyTitle">
                            {data.copyTitle2}
                        </span>
                        <span className="copyBody">
                            {data.copyBody}
                        </span>
                    </div>
                </div>
            )}
            {params.name === "yellow" && (
                <div className="work-open-main">
                    <div className="left-work-grid">
                        <img className="work-pic-big-wide" src={data.altBig} />
                        <div className="grid2x2">
                            {data.grid1.map((item) => {
                                return (
                                    <img className="work-pic-big-wide" src={item.src} />
                                )
                            })}
                        </div>
                        <img className="work-pic-big-wide" src={data.altBig2} />
                        <div className="grid2x2">
                            {data.grid2.map((item) => {
                                return (
                                    <img className="work-pic-big-wide" src={item.src} />
                                )
                            })}
                        </div>
                        <div className="grid2x2">
                            {data.grid3.map((item) => {
                                return (
                                    <img className="work-pic-big-wide" src={item.src} />
                                )
                            })}
                        </div>
                        <div className="gridSurf">
                            {data.grid4.map((item) => {
                                return (
                                    <img src={item.src} />
                                )
                            })}
                        </div>
                        <img className="work-pic-big-wide" src={data.altBig3} />
                    </div>
                    <div className="copy-main">
                        <span className="copyTitle">
                            {data.copyTitle}
                        </span>
                        <span className="copyBody">
                            {data.copyBody}
                        </span>
                    </div>
                </div>
            )}
            {params.name === "ubass-flat" && (
                <div className="work-open-main">
                    <div className="left-work-grid">
                        <img className="work-pic-big-wide" src={data.altBig} />
                        <div style={{ marginBottom: "9px" }} className="grid2x2">
                            {data.altMedia.map((item) => {
                                return (
                                    <img src={item.src} />
                                )
                            })}
                        </div>
                        <div className="grid2x2">
                            {data.altMedia2.map((item) => {
                                return (
                                    <img src={item.src} />
                                )
                            })}
                        </div>
                    </div>
                    <div className="copy-main">
                        <span className="copyTitle">
                            {data.copyTitle}
                        </span>
                        <span className="copyBody">
                            {data.copyBody}
                        </span>
                    </div>
                </div>
            )}
            {params.name === "aja-yoga" && (
                <div className="work-open-main">
                    <div className="left-work-grid">
                        <div className="video-main-alt">
                            <VideoMain props={data} />
                        </div>
                    </div>
                    <div className="copy-main">
                        <span className="copyTitle">
                            {data.copyTitle}
                        </span>
                        <span className="copyBody">
                            {data.copyBody}
                        </span>
                    </div>
                </div>
            )}
        </>
    )
}

export default Work