import React, { useEffect } from "react"

import { Menu, MenuSharp, Close } from "react-ionicons";
import { Link, useHistory } from "react-router-dom";
import useWindowDimensions from "../hooks/useWindowDimensions";

const Header = (props) => {
    const history = useHistory()
    const { height, width } = useWindowDimensions();
    useEffect(() => {
        if (width < 768) {
            props.setMenuOpen(false)
        }
    }, [width])
    return (
        <>
            <header className="header-container">
                <Link className="a" to={"/"}>Studio817</Link>
                {props.menuOpen && (
                    <>
                        <div className="x-menu" onClick={() => props.setMenuOpen(false)}>
                            <Close style={{ color: "#000", height: "30px", width: "30px" }} />
                        </div>
                        <div className="nav-links">

                            <nav>
                                <Link to={"/"}>
                                    Work
                                </Link>
                            </nav>

                            <nav>
                                <Link to="/about">
                                    About
                                </Link>
                            </nav>

                            <nav>
                                <Link to="/contact">
                                    Contact
                                </Link>
                            </nav>
                        </div>
                    </>
                )}
            </header>
            <>
                <div className="right-hand-menu">
                    {props.menuOpen ? (
                        <div className="menuIcon" onClick={() => props.setMenuOpen(false)} />
                    ) : (
                        <MenuSharp className="menuClosed" onClick={() => props.setMenuOpen(true)} style={{ width: "2.22rem", height: "2rem" }} />
                    )}
                    <div className="vertLine" />
                    <div className="insta-icon" onClick={() => window.open('https://www.instagram.com/studioeightoneseven', '_blank').focus()} />
                    <div className="fb-icon" />
                    <div className="twitter-icon" />
                    <div className="vertLine" />
                </div>
            </>
        </>
    )
}

export default Header;
